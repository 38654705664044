import React from "react";

const Header = () => {
  return (
    <nav className=" navbar navbar-expand-lg bg-body-tertiary flex-wrap border  border-top-0 border-end-0 border-start-0">
      <div className="container">
        {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button> */}
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link active text-uppercase"
                aria-current="page"
                href="/home">
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-uppercase" href="/shop">
                shop
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-uppercase" href="/FAQ">
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-uppercase" href="/contact">
                Contact Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link  text-uppercase" href="/about">
                About
              </a>
            </li>
          </ul>
        </div>

        <button
          className="btn btn-primary d-lg-none"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasResponsive"
          aria-controls="offcanvasResponsive">
          &#9776;
        </button>

        <a className="navbar-brand" href="#">
          <span className="text-uppercase d-flex flex-column">
            <span className="text-center">Kassaflora</span>
            <span>LUXURY PRESS - ONS</span>
          </span>
        </a>

        <span className="navbar-text">
          <a className="text-decoration-none" href="#">
            Login In
          </a>
          &nbsp; / &nbsp;
          <a className="text-decoration-none" href="#">
            Sign up
          </a>
          &nbsp;
          <i className="fa-solid fa-user"></i>
          &nbsp;          &nbsp;  
          <i class="fa-solid fa-bag-shopping"></i>
        </span>
      </div>
    </nav>
  );
};

export default Header;
