import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/override.css";
import "./css/main.css";
import Header from "./components/header";
import Footer from "./components/footer";

function App() {
  return (
    <>
      {/* Header */}
      <Header />
      <div
        className="offcanvas-lg offcanvas-start  d-lg-none"
        tabIndex="-1"
        id="offcanvasResponsive"
        aria-labelledby="offcanvasResponsiveLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">
            Responsive offcanvas
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            data-bs-target="#offcanvasResponsive"
            aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <p className="mb-0">
            This is content within an <code>.offcanvas-lg</code>.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
